<template>
  <section class="section is-fullheight">
    <div class="container is-fullheight">
      <div class="columns is-centered is-vcentered is-fullheight">
        <div class="column is-narrow">
          <button
            class="button is-large is-white is-loading"
            v-if="$wait.is('loading')"
          />
          <div class="content" v-if="!$wait.is('loading')">
            <div class="field">
              <VrmTag v-if="vrm" size="large" v-bind="{ vrm }" />
            </div>
            <div class="field" v-if="err">
              <span class="icon is-valigned has-text-danger">
                <i class="fa fa-exclamation-triangle" />
              </span>
              <span class="has-text-grey-light"
                >We are currently unable to value this vehicle</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as companion from 'modules/companion/services'
export default {
  name: 'CompanionStaticSearch',
  components: { VrmTag: () => import('core/components/VrmTag') },
  data: () => ({ err: null }),
  mounted() {
    this.search()
  },
  computed: {
    vrm() {
      return this.$route.query.vrm
    },
    mileage() {
      return this.$route.query.mileage
    },
    token() {
      return this.$route.query.token
    },
    userId() {
      return this.$route.query.user_id
    },
    dealerId() {
      return this.$route.query.dealer_id
    }
  },
  methods: {
    async search() {
      this.$wait.start('loading')
      this.$store.commit('auth/unsetToken')
      this.$store
        .dispatch('auth/staticLogIn', this.token)
        .catch(() => (this.err = 'Error authenticating'))
        
        this.$mxp.track('static_search', { dealer_id: this.dealerId, user_id: this.userId, vrm: this.vrm, mileage: this.mileage })
      
        await companion
        .value({ vrm: this.vrm, isStatic: true, mileage: this.mileage })
        .catch(err => {
          if (err) this.err = err
        })
        .finally(() => this.$wait.end('loading'))
    }
  }
}
</script>

<style lang="sass" scoped>
.loading
  display: flex
  justify-content: center
  align-items: center
  position: fixed
  top: 0
  left: 0
  height: 100vh
  width: 100vw
  background: rgba(0, 0, 0, 0.75)
</style>
